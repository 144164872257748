import { theme } from 'src/styles/theme'
import styled from 'styled-components'
import {
  border,
  color,
  ColorProps,
  compose,
  flexbox,
  layout,
  position,
  space,
  typography
} from 'styled-system'

import { CountdownCircleTimerProps } from './CountdownCircleTimer.types'

export const CountdownCircleTimerLabel = styled.span<CountdownCircleTimerProps>`
  ${compose(color, typography)}
`

export const CountdownCircleTimerWrapper = styled.div<CountdownCircleTimerProps>`
  cursor: pointer;
`

export const CountdownCircleTimerBg = styled.div<CountdownCircleTimerProps>`
  ${compose(layout, space, color, position, border, typography)}
`
