import { ButtonWrapper as ButtonWrapperStyled } from './Button.styles'
import { ButtonProps } from './Button.types'

export const ButtonWrapper = (props: ButtonProps) => {
  const { dark, outlined, children, mobLarge, toUrl = '/#', ...rest } = props

  return (
    <ButtonWrapperStyled
      px="1.25rem"
      minWidth={{ _: '6.111rem', xs: '300px' }}
      height={{ _: mobLarge ? '3.125rem' : '2.222rem', xs: '3.333rem' }}
      fontSize={{ _: '0.875rem', xs: 2 }}
      fontWeight={{ _: '400', xs: '500' }}
      borderStyle="solid"
      borderRadius={16}
      onClick={() => (window.location.href = toUrl)}
      {...rest}
    >
      {children}
    </ButtonWrapperStyled>
  )
}
