import { useContext } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AuthContext from '../store/Auth/auth-context'

import Home from './Home'
import Login from './Login'
import PasswordReset from './PasswordReset'
import PasswordRecovery from './PasswordRecovery'
import { WebCheckin } from './WebCheckin'

const RouterManager = () => {
  const authCtx = useContext(AuthContext)

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        {!authCtx.isLoggedIn && (
          <Switch>
            <Route path="/">
              <WebCheckin />
            </Route>
            {/* @ts-ignore */}
            {/* <Route path="/password_reset">
              <PasswordReset />
            </Route>
            <Route path="/password_recovery">
              <PasswordRecovery />
            </Route>
            <Route path="/web-checkin-campain">
              <WebCheckin />
            </Route> */}
          </Switch>
        )}
        {authCtx.isLoggedIn && (
          <Switch>
            {/* @ts-ignore */}
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        )}
      </Router>
    </>
  )
}

export default RouterManager
