import styled from 'styled-components'
import {
  border,
  color,
  compose,
  flexbox,
  layout,
  space,
  typography
} from 'styled-system'

import { FeatureProps } from './Feature.types'

export const FeatureWrapper = styled.div<FeatureProps>`
  ${compose(space, layout, flexbox, border)}
`

export const FeatureList = styled.div<FeatureProps>`
  ${compose(space, layout, flexbox, color)}
`

export const FeatureListItem = styled.div<FeatureProps>`
  ${compose(space, layout, flexbox, color, typography)}
  font-family:"Poppins";
`

export const FeatureImg = styled.img<FeatureProps>``

export const FeatureTitle = styled.div<FeatureProps>`
  ${compose(typography, color)}
`

export const FeatureContent = styled.div<FeatureProps>`
  ${compose(space, layout, flexbox, typography)}
`

export const FeatureCol = styled.div<FeatureProps>`
  ${compose(space)}
`
