import LogoCdbSvg from '../../assets/images/logos/logo_cdb.svg'
import LogoCdbWhiteSvg from '../../assets/images/logos/logo_white_cdb.svg'
import LogoAxial from '../../assets/images/logos/logo_axial.png'
import LogoCedimagem from '../../assets/images/logos/logo_cedimagem.png'
import LogoDelfin from '../../assets/images/logos/logo_delfin.png'
import LogoNuclear from '../../assets/images/logos/logo_nuclear.png'
import LogoSabedotti from '../../assets/images/logos/logo_sabedotti.png'
import LogoCSD from '../../assets/images/logos/logo_csd.png'
import LogoPlani from '../../assets/images/logos/logo_plani.png'
import LogoStj from '../../assets/images/logos/logo_stj.png'
import LogoDiimagem from '../../assets/images/logos/logo_diimagem.png'
import LogoMultiscan from '../../assets/images/logos/logo_multiscan.png'

import { LogoImg } from './styles'
import { LayoutProps, SpaceProps } from 'styled-system'

interface LogoProps extends SpaceProps, LayoutProps {
  dark?: boolean
  domain?: string
}

const mapLogo = [
  {
    domain: 'cdb',
    colorful: LogoCdbSvg,
    white: LogoCdbWhiteSvg
  },
  {
    domain: 'goldimagem',
    colorful: LogoCdbSvg,
    white: LogoCdbWhiteSvg
  },
  {
    domain: 'umdi',
    colorful: LogoCdbSvg,
    white: LogoCdbWhiteSvg
  },
  {
    domain: 'axial',
    colorful: LogoAxial,
    white: LogoAxial
  },
  {
    domain: 'cedimagem',
    colorful: LogoCedimagem,
    white: LogoCedimagem
  },
  {
    domain: 'sabedotti',
    colorful: LogoSabedotti,
    white: LogoSabedotti
  },
  {
    domain: 'delfin',
    colorful: LogoDelfin,
    white: LogoDelfin
  },
  {
    domain: 'gco',
    colorful: LogoDiimagem,
    white: LogoDiimagem
  },
  {
    domain: 'alliares',
    colorful: LogoMultiscan,
    white: LogoMultiscan
  },
  {
    domain: 'nuclear',
    colorful: LogoNuclear,
    white: LogoNuclear
  },
  {
    domain: 'plani',
    colorful: LogoPlani,
    white: LogoPlani
  },
  {
    domain: 'stj',
    colorful: LogoStj,
    white: LogoStj
  },
  {
    domain: 'som',
    colorful: LogoCSD,
    white: LogoCSD
  }
]

export const Logo = ({ dark, domain = 'cdb', ...rest }: LogoProps) => {
  const logo = mapLogo.find(logoValues => logoValues.domain === domain)

  return logo ? (
    <LogoImg
      src={dark ? logo.white : logo.colorful}
      alt="Logo"
      {...(rest as any)}
    />
  ) : (
    <div />
  )
}
