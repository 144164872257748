import styled from 'styled-components'
import {
  color,
  compose,
  flexbox,
  layout,
  space,
  typography
} from 'styled-system'
import { FaqProps } from './Faq.types'

export const FaqWrapper = styled.div<FaqProps>`
  ${compose(layout, space)}
  font-family: 'Poppins', sans-serif;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #191d4526;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
`

export const FaqTitle = styled.div<FaqProps>`
  ${compose(layout, space, typography, color, flexbox)}
`

export const FaqCollapse = styled.div<FaqProps>`
  ${compose(layout, space, typography)}
  text-align: left;
  color: #828894;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  overflow: hidden;
`
