import styled from 'styled-components'
import { layout, flexbox, space, color, compose } from 'styled-system'
import { NavBarContainerProps } from './NavBar.types'

export const NavBarContainer = styled('div')<NavBarContainerProps>(
  compose(space, layout, flexbox, color)
)

export const NavWrapper = styled('div')<NavBarContainerProps>`
  ${compose(layout, flexbox)}
`
