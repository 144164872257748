import {
  MainBannerChildren,
  MainBannerContainer,
  MainBannerSubTitle,
  MainBannerTitle,
  MainBannerWrapper
} from './MainBanner.styles'

export const MainBanner = (props: any) => {
  return (
    <MainBannerContainer
      {...props}
      height={{ _: '26rem', xs: '26rem' }}
      display="flex"
      justifyContent="center"
    >
      <MainBannerWrapper
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={{ _: '100%', xs: '56.889rem' }}
      >
        <MainBannerTitle
          fontSize={{ _: '1.778rem', xs: '3.75rem' }}
          mt={{ _: '5rem', xs: '8.111rem' }}
          lineHeight={{ _: '3rem', xs: '5.625rem' }}
        >
          {props.title}
        </MainBannerTitle>
        <MainBannerSubTitle
          mt={{ _: '0.556rem', xs: 1 }}
          fontSize={{ _: '1rem', xs: '1.125rem' }}
          textAlign="center"
          maxWidth={{ _: '17.778rem', xs: '50rem' }}
          lineHeight={{ _: '1.563rem', xs: '1.389rem' }}
          fontWeight="200"
        >
          {props.subtitle}
        </MainBannerSubTitle>
        <MainBannerChildren
          width="100%"
          display="flex"
          flexDirection={{ _: 'column-reverse', xs: 'row' }}
          justifyContent="center"
        >
          {props.children}
        </MainBannerChildren>
      </MainBannerWrapper>
    </MainBannerContainer>
  )
}
