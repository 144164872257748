import { theme } from 'src/styles/theme'
import { ButtonProps } from './Button.types'
import { ButtonWrapper } from './ButtonWrapper'

export const OutlinedButton = (props: ButtonProps) => {
  const { dark, children, ...rest } = props
  const { colors } = theme

  return (
    <ButtonWrapper
      {...rest}
      color={!dark ? colors.cdb.primary : colors.white}
      borderColor={!dark ? colors.cdb.primary : colors.white}
      backgroundColor="transparent"
      borderWidth={2}
    >
      {children}
    </ButtonWrapper>
  )
}
