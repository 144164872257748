import { ReactNode } from 'react'
import { NavBarContainer, NavWrapper } from './NavBar.styles'

interface NavBarProps {
  id?: string
  children?: ReactNode
}

export const NavBar = (props: NavBarProps) => {
  return (
    <NavBarContainer
      height={{ _: '4.25rem', xs: '6.75rem' }}
      px={{ _: '0.5rem', sm: '1rem' }}
      bg="white"
      display="flex"
      justifyContent="center"
    >
      <NavWrapper
        width={{ _: '100%', xs: '70.375rem' }}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {props.children}
      </NavWrapper>
    </NavBarContainer>
  )
}
