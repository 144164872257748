import {
  CountdownCircleTimer as CountdownCircleTimerComponent,
  useCountdown
} from 'react-countdown-circle-timer'
import { ColorFormat } from 'react-countdown-circle-timer/lib/types'
import { theme } from 'src/styles/theme'
import {
  CountdownCircleTimerBg,
  CountdownCircleTimerLabel,
  CountdownCircleTimerWrapper
} from './CountdownCircleTimer.styles'
import { CountdownCircleTimerProps } from './CountdownCircleTimer.types'

export const CountdownCircleTimer = ({
  label = '0',
  timeLimitInSeconds = 15,
  isActive = false,
  wasRead = false,
  onClick,
  action
}: CountdownCircleTimerProps) => {
  useCountdown({ isPlaying: true, duration: 7, colors: '#abc' })

  let strokeColor = theme.colors.white80 as ColorFormat

  if (isActive || wasRead) {
    strokeColor = theme.colors.cdb.primary as ColorFormat
  }

  return (
    <CountdownCircleTimerWrapper onClick={onClick}>
      <CountdownCircleTimerComponent
        key={label + isActive}
        isPlaying={isActive}
        duration={timeLimitInSeconds}
        size={50}
        colors={strokeColor}
        strokeWidth={isActive ? 4 : 0}
        trailColor={
          wasRead
            ? (theme.colors.cdb.primary as ColorFormat)
            : (theme.colors.romanSilver as ColorFormat)
        }
        trailStrokeWidth={isActive ? 3 : 0}
        onComplete={action}
      >
        {() => (
          <CountdownCircleTimerBg
            bg={wasRead ? theme.colors.cdb.primary : theme.colors.white80}
            minWidth="48px"
            py="16px"
            zIndex={-1}
            borderRadius="50%"
            textAlign="center"
          >
            <CountdownCircleTimerLabel
              fontFamily={'Poppins'}
              fontSize={'1.125rem'}
              width="2.889rem"
              height="2.889rem"
              color={wasRead ? theme.colors.white : theme.colors.cdb.primary}
              fontWeight="500"
            >
              {label}
            </CountdownCircleTimerLabel>
          </CountdownCircleTimerBg>
        )}
      </CountdownCircleTimerComponent>
    </CountdownCircleTimerWrapper>
  )
}
