import { theme } from 'src/styles/theme'
import styled from 'styled-components'
import {
  color,
  compose,
  flexbox,
  layout,
  space,
  typography
} from 'styled-system'
import { TimelineBulletsProps, TimelineProps } from './Timeline.types'

export const TimelineContainer = styled.div<TimelineProps>`
  ${compose(layout, flexbox, space)}
  display: flex;
  font-family: 'Poppins', sans-serif;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.111rem;
`

export const TimelineBreakpointControl = styled.div<TimelineProps>`
  ${compose(layout, flexbox)}
  display: flex;
  flex-wrap: nowrap;
`

export const TimelineCounterWrapper = styled.div<TimelineProps>`
  ${compose(layout, flexbox)}
  display: flex;
  position: relative;
`
export const TimelineCounterMobileBackBar = styled.div<TimelineProps>`
  ${compose(layout, flexbox)}
  display: flex;
  &:before {
    content: ' ';
    position: absolute;
    background: ${() => theme.colors.white70};
    width: 60vw;
    z-index: -10;
    margin-top: 1.375rem;
    height: 6px;
    left: 17vw;
  }
  :after {
    content: ' ';
    position: absolute;
    background: ${() => theme.colors.primary};
    width: ${props => (props.sizeBarWidth ? props.sizeBarWidth : '60vw')};
    z-index: -10;
    margin-top: 1.375rem;
    height: 6px;
    left: 17vw;
    -webkit-transition: all 800ms ease;
    -moz-transition: all 800ms ease;
    -ms-transition: all 800ms ease;
    -o-transition: all 800ms ease;
    transition: all 800ms ease;
  }
`
export const TimelineCounterDesktopBackBar = styled.div<TimelineProps>`
  ${compose(layout, flexbox)}
  &:before {
    content: ' ';
    position: absolute;
    background: ${() => theme.colors.white70};
    width: 6px;
    height: 21rem;
    z-index: -10;
    top: 1.375rem;
    left: 1.375rem;
  }
  :after {
    content: ' ';
    position: absolute;
    background: ${() => theme.colors.primary};
    height: ${props => (props.sizeBarWidth ? props.sizeBarWidth : '20rem')};
    width: 6px;
    z-index: -10;
    top: 1.375rem;
    left: 1.375rem;
    -webkit-transition: all 800ms ease;
    -moz-transition: all 800ms ease;
    -ms-transition: all 800ms ease;
    -o-transition: all 800ms ease;
    transition: all 800ms ease;
  }
`

export const TimelineMobileContentWrapper = styled.div<TimelineProps>`
  ${compose(space, layout, flexbox)}
  width: 100%;
  align-items: center;
  text-align: center;
`

export const TimelineDesktopContentWrapper = styled.div<TimelineProps>`
  ${compose(space, layout, flexbox)}
`

export const TimelineWrapper = styled.div<TimelineProps>`
  ${compose(space, layout, flexbox)}
`

export const TimelineContent = styled.div<TimelineProps>`
  ${compose(space, layout, flexbox)}
  display: flex;
  flex-direction: column;
  align-content: center;
`

export const TimelineImageWrapper = styled.div<TimelineProps>`
  ${compose(space, layout, flexbox)}
  display: flex;
  flex-wrap: nowrap;
`

export const TimelineImage = styled.img<TimelineProps>`
  ${compose(space, layout, flexbox)}
  width: 100%;
`

export const TimelineTitle = styled.div<TimelineProps>`
  ${compose(typography, color, space)}
`
export const TimelineText = styled.div<TimelineProps>`
  ${compose(typography)}
`

export const TimelineBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`

export const TimelineBulletsItem = styled.div<TimelineBulletsProps>`
  ${compose(space, layout, flexbox, color)}
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
`
