import step01 from '../assets/images/step_01.png'
import step02 from '../assets/images/step_02.png'
import step03 from '../assets/images/step_03.png'
import step04 from '../assets/images/step_04.png'

export const TimelineItems = [
  {
    id: '0',
    title: 'Digitalize seus documentos',
    text: 'Tire uma foto do seu Documento Pessoal (RG, CNH, Passaporte ou Carteira de Trabalho), Carteirinha do Plano de Saúde e Pedido Médico.',
    img: step01,
    isActive: false
  },
  {
    id: '1',
    title: 'Faça login na plataforma',
    text: 'Preencha com seu CPF e sua senha e clique em “Acessar”.',
    img: step02,
    isActive: false
  },
  {
    id: '2',
    title: 'Identifique o protocolo',
    text: 'Localize o número do protocolo e clique no ícone de documentos.',
    img: step03,
    isActive: false
  },
  {
    id: '3',
    title: 'Insira seus documentos',
    text: 'Faça o envio dos seus documentos clicando em  “+ Adicionar”.',
    img: step04,
    isActive: false
  }
]
