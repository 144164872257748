import {
  FiAlertCircle,
  FiEye,
  FiEyeOff,
  FiHelpCircle,
  FiLock,
  FiMail,
  FiCheck,
  FiX,
  FiChevronDown
} from 'react-icons/fi'
import { IconProps } from 'src/types/Icon.types'
import { IconWrapper } from './styles'

const IconSetter = ({ name, color, size = 0 }: IconProps) => {
  switch (name) {
    case 'alert-circle':
      return <FiAlertCircle color={color?.toString()} size={Number(size)} />

    case 'check':
      return <FiCheck color={color?.toString()} size={Number(size)} />

    case 'eye':
      return <FiEye color={color?.toString()} size={Number(size)} />

    case 'eye-off':
      return <FiEyeOff color={color?.toString()} size={Number(size)} />

    case 'lock':
      return <FiLock color={color?.toString()} size={Number(size)} />

    case 'mail':
      return <FiMail color={color?.toString()} size={Number(size)} />

    case 'close':
      return <FiX color={color?.toString()} size={Number(size)} />
    case 'chevron-down':
      return <FiChevronDown color={color?.toString()} size={Number(size)} />

    default:
      return <FiHelpCircle color={color?.toString()} size={Number(size)} />
  }
}

const Icon = (props: IconProps) => {
  const { onClick, name, size, ...rest } = props
  return (
    // @ts-ignore
    <IconWrapper
      {...rest}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <IconSetter name={name} size={size} />
    </IconWrapper>
  )
}

export default Icon
