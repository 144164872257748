import styled from 'styled-components'
import { compose, space, layout, color, typography } from 'styled-system'
import { ComposeProps } from './WebCheckin.types'

export const Container = styled.div<ComposeProps>`
  ${compose(color, space, layout)}
`
export const CenterWrapper = styled.div<ComposeProps>`
  ${compose(space, layout, typography)}
`
