export const FaqItems = [
  {
    title: 'O que é o Web Check-In?',
    text: 'O Web Check-In é um canal que te leva a um atendimento rápido e prático para a realização dos seus exames. Ao realizar o envio de seus documentos como carteirinha do convênio, documento com foto (RG, CNH, Carteira de Trabalho, Passaporte), pedido médico, autorizações dos exames e laudos de exames anteriores, quando necessários, você agiliza seu atendimento desde a chegada em uma de nossas lojas.',
    collapsed: true
  },
  {
    title: 'Por que preciso fazer o Web Check-In?',
    text: 'Ao realizar o Web Check-In você tem um atendimento mais rápido,  pois a etapa de confirmação dos seus dados e exames, já foram realizadas.',
    collapsed: true
  },
  {
    title: 'Qual o prazo máximo para fazer o Web Check-In?',
    text: 'Você pode realizar com até 24 horas de antecedência da data/horário do seu agendamento.',
    collapsed: true
  },
  {
    title:
      'Já fiz o Web Check-In, preciso levar meus documentos no dia do atendimento?',
    text: 'Sim, é necessário levar seus documentos pessoais originais (RG, CNH ou Carteira de Trabalho), pedido médico original, caso não seja particular, levar carteirinha do convênio (física ou digital).',
    collapsed: true
  },
  {
    title: 'O que devo fazer ao chegar na unidade?',
    text: 'Ao chegar na unidade, você irá retirar sua senha de atendimento web Check-In, lembre-se de utilizar seu QR Code.',
    collapsed: true
  }
]
