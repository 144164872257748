import { useState } from 'react'
import { theme } from 'src/styles/theme'
import Icon from '../Icon'
import { FaqCollapse, FaqTitle, FaqWrapper } from './Faq.styles'
import { FaqProps } from './Faq.types'

export const Faq = (props: FaqProps) => {
  const [faqItems, setFaqItems] = useState(props.items)

  const collapseHandler = (index: number) => {
    const mapCollapsedItem = faqItems?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          collapsed: !item.collapsed
        }
      }
      return {
        ...item,
        collapsed: true
      }
    })

    setFaqItems(mapCollapsedItem)
  }

  return (
    <>
      {faqItems?.map((item, index) => (
        <FaqWrapper
          key={index}
          mx={{ _: '1.375rem', xs: 'auto' }}
          mb={{ _: '0.5rem', xs: '1.875rem' }}
          px={{ _: '1rem', xs: '1.875rem' }}
          pt={{ _: '1.813rem', xs: '1.875rem' }}
          pb={{ _: '1.5rem' }}
          maxWidth={{ _: '100%', xs: '61rem' }}
          onClick={() => collapseHandler(index)}
        >
          <FaqTitle
            fontSize={{ _: '0.875rem', xs: '1rem' }}
            textAlign="left"
            letterSpacing="0px"
            color="#0c274d"
            fontWeight="500"
            marginBottom="0.813rem"
            lineHeight={{ _: '1.313rem', xs: '1.563rem' }}
            display="flex"
            justifyContent="space-between"
          >
            {index + 1}. {item.title}
            <Icon
              name={'chevron-down'}
              width={30}
              color={theme.colors.cdb.secondary}
              // ml={0.5}
              // mx={1}
              rotate={item.collapsed ? 0 : 180}
              size={30}
            />
          </FaqTitle>
          <FaqCollapse
            maxHeight={item.collapsed ? 0 : '50rem'}
            isCollapsed={item.collapsed}
            fontSize={{ _: '0.875rem', xs: '1rem' }}
            letterSpacing="0px"
            lineHeight={{ _: '1.313rem', xs: '1.563rem' }}
          >
            {item.text ? item.text : 'Sem texto'}
          </FaqCollapse>
        </FaqWrapper>
      ))}
    </>
  )
}
