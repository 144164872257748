import styled from 'styled-components'
import {
  color,
  compose,
  layout,
  LayoutProps,
  space,
  typography,
  TypographyProps
} from 'styled-system'
import { WebCheckinTitleProps } from '../types'

export const Strong = styled.span<TypographyProps>`
  font-weight: 500;
`

export const H1 = styled.h1<TypographyProps>``
export const H2 = styled.h2<TypographyProps>`
  font-size: 1.875em;
  font-family: Poppins;
  color: ${props => props.color || 'black'};
`
export const H3 = styled.h3<TypographyProps | LayoutProps>`
  ${compose(space, typography, layout)}
  font-family: Poppins;
`

export const H4 = styled.h4<TypographyProps>`
  font-family: Poppins;
  font-size: 1.125em;
`
export const H5 = styled.h5<TypographyProps>`
  ${compose(space, typography)}
  font-family: Poppins;
  font-size: 0.875em;
`

export const H6 = styled.h6<TypographyProps>`
  ${compose(space, typography)}
  font-size: 12pt;
  font-family: Poppins;
  margin: 0;
`

export const P = styled.p<TypographyProps>`
  ${compose(space, typography)}
  flex: auto;
  font-family: 'roboto';
`

export const WebCheckinTitle = styled.div<WebCheckinTitleProps>`
  ${compose(space, typography, color, layout)}
  text-align: center;
  line-height: 1.833rem;
  align-self: center;
  margin-top: 2.889rem;
  font-family: 'Poppins';
`
