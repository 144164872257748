import { theme } from 'src/styles/theme'
import { H4 } from '../../styles/common/typography'
import { FooterContainer, FooterWrapper } from './styles'

interface FooterProps {
  readonly id: string
  readonly mensage: string
}

const Footer = (props: FooterProps) => {
  const { id, mensage } = props
  return (
    <FooterWrapper id={id}>
      <FooterContainer
        bg={theme.colors.cdb.secondary}
        color={theme.colors.white}
      >
        <H4>{mensage}</H4>
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer
