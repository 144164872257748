import { useState, useEffect, useRef } from 'react'
import AuthContext from './auth-context'
import useHttp from '../../hooks/use-http'

const AuthProvider = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [authError, setAuthError] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const authToken = useRef<string>('')
  const { sendRequest } = useHttp()

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken')
    const refreshToken = localStorage.getItem('refreshToken')
    if (storedToken && storedToken.trim().length > 0) {
      setIsLoggedIn(true)
      authToken.current = storedToken
    }
    if (refreshToken && refreshToken.trim().length > 0) {
      setRefreshToken(refreshToken)
    }
  }, [])

  const handleReturn = (requestData: any) => {
    setIsLoggedIn(true)
    authToken.current = requestData.access_token
    setRefreshToken(requestData.refresh_token)
    localStorage.setItem('authToken', requestData.access_token)
    localStorage.setItem('refreshToken', requestData.refresh_token)
  }

  const renewToken = async () => {
    await sendRequest(
      {
        url: `${process.env.REACT_APP_BFF_URL}/keycloak/refresh_token`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          refresh_token: refreshToken
        }
      },
      handleReturn,
      handleRenewFailure
    )
  }

  const handleRenewFailure = (error: any) => {
    if (error.response.status === 401) {
      logOutHandler()
    }
  }
  const logInHandler = async (
    cpf: string,
    password: string,
    captcha: string
  ) => {
    await sendRequest(
      {
        url: `${process.env.REACT_APP_BFF_URL}/keycloak/login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          username: cpf,
          password: password,
          recaptcha_response: captcha
        }
      },
      handleReturn,
      handleError
    )
  }

  const handleError = (message: string) => {
    setAuthError(message)
  }

  const logOutHandler = () => {
    setIsLoggedIn(false)
    authToken.current = ''
    localStorage.removeItem('authToken')
  }

  const clearError = () => {
    setAuthError('')
  }

  const authContext = {
    token: authToken,
    isLoggedIn: isLoggedIn,
    authError: authError,
    renewToken: renewToken,
    logIn: logInHandler,
    clearError: clearError,
    logOut: logOutHandler
  }

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
