import { useState } from 'react'

import { CountdownCircleTimer } from '../CountdownCircleTimer'
import {
  TimelineBreakpointControl,
  TimelineContainer,
  TimelineContent,
  TimelineMobileContentWrapper,
  TimelineCounterWrapper,
  TimelineImage,
  TimelineImageWrapper,
  TimelineTitle,
  TimelineText,
  TimelineCounterMobileBackBar,
  TimelineCounterDesktopBackBar,
  TimelineDesktopContentWrapper,
  TimelineWrapper
} from './Timeline.styles'
import { TimelineItemsProps, TimelineProps } from './Timeline.types'
import { TimelineBullets } from './TimelineBullets'

export const Timeline = ({ items }: TimelineProps) => {
  const [step, setStep] = useState(0)

  const barResizer = (step: number, breakpoint: string) => {
    if (breakpoint === 'mobile') {
      if (step < 1) {
        return '0vw'
      }
      return 53 * ((step + 1) / 4) + 'vw'
    }

    if (breakpoint === 'desktop') {
      if (step < 1) {
        return '0rem'
      }
      return 19 * ((step + 1) / 4) + 'rem'
    }
  }

  return (
    <TimelineContainer
      flexDirection={{ _: 'column', xs: 'row' }}
      maxWidth={{ _: 'auto', xs: '64rem' }}
      mx={{ _: 'auto', xs: 'auto' }}
    >
      <TimelineCounterWrapper
        flexDirection={{ _: 'row', xs: 'column' }}
        height={{ _: 'auto', xs: '26rem' }}
        width="100%"
        justifyContent="space-evenly"
      >
        {items?.map((item: TimelineItemsProps, index: number) => {
          return (
            <TimelineWrapper key={index} display="flex">
              <CountdownCircleTimer
                label={`${index + 1}`}
                wasRead={Number(item.id) < step}
                isActive={step === index}
                onClick={() => setStep(index)}
                action={() => (step === 3 ? setStep(0) : setStep(step + 1))}
                timeLimitInSeconds={15}
              />
              <TimelineDesktopContentWrapper
                display={{ _: 'none', xs: 'flex' }}
                width="26.5rem"
              >
                <TimelineContent px="2.5rem" key={item.id}>
                  <TimelineTitle
                    fontSize={1}
                    fontWeight={500}
                    color={
                      step === index || Number(item.id) < step
                        ? '#2984c3'
                        : '#4a4a4a'
                    }
                    mt="0.5rem"
                    mb="0.5rem"
                  >
                    {item.title}
                  </TimelineTitle>
                  <TimelineText fontSize="0.875rem" lineHeight="1.313rem">
                    {item.text}
                  </TimelineText>
                </TimelineContent>
              </TimelineDesktopContentWrapper>
            </TimelineWrapper>
          )
        })}

        <TimelineCounterMobileBackBar
          sizeBarWidth={barResizer(step, 'mobile')}
          display={{ _: 'flex', xs: 'none' }}
        />

        <TimelineCounterDesktopBackBar
          sizeBarWidth={barResizer(step, 'desktop')}
          display={{ _: 'none', xs: 'flex' }}
        />
      </TimelineCounterWrapper>

      <TimelineBreakpointControl flexDirection="column" alignItems="center">
        <TimelineImageWrapper
          mt={6}
          flexDirection="column"
          maxWidth={{ _: '17.5rem', xs: '35rem' }}
          minHeight={'11.25rem'}
        >
          {items
            ?.filter(({ id }: TimelineItemsProps) => Number(id) === step)
            .map(({ id, img }: TimelineItemsProps) => (
              <TimelineImage key={id} src={img} />
            ))}
        </TimelineImageWrapper>

        <TimelineBullets step={step} mt={{ _: 3 }} items={items} />

        <TimelineMobileContentWrapper
          display={{ _: 'flex', xs: 'none' }}
          mt={2}
          flexDirection={{ _: 'row', xs: 'column' }}
        >
          {items
            ?.filter(({ id }: TimelineItemsProps) => Number(id) === step)
            .map(({ id, title, text }: TimelineItemsProps) => (
              <TimelineContent key={id}>
                <TimelineTitle fontSize="0.875rem">{title}</TimelineTitle>
                <TimelineText
                  fontSize="0.75rem"
                  fontWeight="500"
                  lineHeight="1.125rem"
                >
                  {text}
                </TimelineText>
              </TimelineContent>
            ))}
        </TimelineMobileContentWrapper>
      </TimelineBreakpointControl>
    </TimelineContainer>
  )
}
