import { theme } from 'src/styles/theme'
import {
  FeatureCol,
  FeatureContent,
  FeatureImg,
  FeatureList,
  FeatureListItem,
  FeatureTitle,
  FeatureWrapper
} from './Feature.styles'

export const Feature = (props: any) => {
  return (
    <FeatureWrapper
      display="block"
      borderBottom="solid"
      borderWidth={2}
      borderColor="#787b8a36"
    >
      <FeatureList
        id="FeatureList"
        bg="background"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        py={{ _: '0.889rem' }}
      >
        {props.items
          ? props.items.map((item: any, index: number) => (
              <FeatureListItem
                id={'FeatureListItem_' + index}
                key={index}
                display="flex"
                width={{ _: '23rem', xs: '23.5rem' }}
                my={{ _: '0.889rem' }}
                px={{ _: '1rem', xs: '0rem' }}
              >
                <FeatureCol>
                  <FeatureImg src={item.image} alt="Praticidade Icon" />
                </FeatureCol>

                <FeatureCol
                  pl={{ _: '1.5rem', xs: '2rem' }}
                  mr={{ _: '1.125rem' }}
                >
                  <FeatureTitle
                    fontSize={{ _: 1, xs: '1.125rem' }}
                    fontWeight={500}
                    color={theme.colors.cdb.secondary}
                  >
                    {item.title}
                  </FeatureTitle>
                  <FeatureContent
                    fontSize={{ _: '0.75rem', xs: '0.875rem' }}
                    lineHeight={{ _: '1.125rem', xs: '1rem' }}
                    mt={{ _: '0.5rem', xs: '1rem' }}
                  >
                    {item.description}
                  </FeatureContent>
                </FeatureCol>
              </FeatureListItem>
            ))
          : null}
      </FeatureList>
    </FeatureWrapper>
  )
}
