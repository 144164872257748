import PraticidadeImg from '../assets/images/praticidade.svg'
import AgilidadeImg from '../assets/images/agilidade.svg'
import SegurancaImg from '../assets/images/seguranca.svg'

export const FeatureTips = [
  {
    title: 'Praticidade',
    description:
      'Você pode adicionar seus documentos utilizando a câmera do seu celular',
    image: PraticidadeImg
  },
  {
    title: 'Agilidade',
    description: 'Seu atendimento será mais rápido e menos burocrático',
    image: AgilidadeImg
  },
  {
    title: 'Segurança',
    description: 'Seus documentos estão seguros com a gente',
    image: SegurancaImg
  }
]
