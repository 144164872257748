import { useContext } from 'react'
import useHttp from './use-http'
import AuthContext from '../store/Auth/auth-context'

const useBffClient = () => {
  const { sendRequest } = useHttp()
  const authCtx = useContext(AuthContext)

  const httpPost = async (
    method: string,
    body: any,
    headers: any,
    callbackHandler: any,
    errorHandler: any
  ) => {
    const handleFirstError = async (error: any) => {
      if (error.response.status === 403) {
        await authCtx.renewToken()
      } else {
        errorHandler(error)
        return
      }

      await sendRequest(
        {
          url: `${process.env.REACT_APP_BFF_URL}/api/${method}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token?.current}`,
            ...headers
          },
          body: body
        },
        callbackHandler,
        handleSecondError
      )
    }

    const handleSecondError = async (error: any) => {
      if (error.response.status === 403) return

      errorHandler(error)
    }

    await sendRequest(
      {
        url: `${process.env.REACT_APP_BFF_URL}/api/${method}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token?.current}`,
          ...headers
        },
        body: body
      },
      callbackHandler,
      handleFirstError
    )
  }

  return {
    httpPost
  }
}

export default useBffClient
