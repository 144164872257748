import styled, { css } from 'styled-components'
import { SpaceProps } from 'styled-system'

interface ButtonStyledProps {
  round: boolean
  disabled: boolean
  width?: string
  height?: string
  icon?: boolean
}

const MainButton = styled.button<ButtonStyledProps>`
  align-items: center;
  background-color: #8fc043;
  border-radius: ${props => (props.round ? '30px' : '10px')};
  border: 2px solid;
  border-color: #8fc043;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 300;
  color: ${props => props.theme.colors.primary_text};
  padding: ${props => (props.round ? '0' : '10px 30px')};
  min-width: ${props => (props.round ? 'unset' : '200px')};
  min-height: ${props => (props.round ? 'unset' : '60px')};
  width: ${props => (props.round ? '50px' : props.width || 'unset')};
  display: ${props => (props.icon ? 'flex' : 'block')};
  justify-content: center;
  height: ${props => (props.round ? '50px' : props.height || 'unset')};
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
  ${props => props.round && 'font-size: 24px;'}
  ${props => props.disabled && 'opacity: 0.4;'}
  ${props =>
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        cursor: pointer;
        justify-content: space-between;
        opacity: 0.7;
      }
    `}
  & > svg {
    margin-left: 10px;
    width: 25px;
    height: 25px;
  }
`

const PrimaryButton = styled(MainButton)`
  background-color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary_text};
`

const SecondaryButton = styled(MainButton)`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};
`

const LinkButton = styled(MainButton)`
  color: ${props => props.theme.colors.primary};
  border: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 16px;
  min-width: unset;
  min-height: unset;
  width: unset;
  padding: 0;
  margin: 0;

  & > svg {
    margin-left: 5px;
    width: 16px;
    height: 10px;
  }

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
      }
    `}
`

interface ButtonProps extends SpaceProps {
  /**
   * Conteúdo do botão
   */
  label: string
  /**
   * Handler opcional para o evento de clique
   */
  onClick?: any

  /**
   * Tema do sistema
   */
  theme?: any

  /**
   * Qual variante de botão será utilizada?
   */
  style?: 'main' | 'primary' | 'secondary' | 'link'

  /**
   * Aplicar o estilo de botão circular?
   */
  round?: boolean

  /**
   * Botão habilitado ou desabilitado?
   */
  disabled?: boolean

  /**
   * Largura (aceita qualquer unidade)
   */
  width?: string

  /**
   * Altura (aceita qualquer unidade)
   */
  height?: string

  /**
   * Ícone do botão
   */
  icon?: any

  /**
   * Propriedade type
   */
  type?: 'button' | 'submit'
}

/**
 * Botão principal para uso no sistema
 */
export const Button = ({
  label,
  round = false,
  disabled = false,
  style = 'primary',
  icon,
  width,
  type = 'button',
  ...props
}: ButtonProps) => {
  if (round) label = '+'

  switch (style) {
    case 'main': {
      return (
        <MainButton
          disabled={disabled}
          round={round}
          type={type}
          icon={icon}
          width={width}
          {...props}
        >
          {label}
          {!round && icon}
        </MainButton>
      )
    }
    case 'secondary': {
      return (
        <SecondaryButton
          disabled={disabled}
          round={round}
          type={type}
          icon={icon}
          width={width}
          {...props}
        >
          {label}
          {!round && icon}
        </SecondaryButton>
      )
    }
    case 'link': {
      return (
        <LinkButton
          disabled={disabled}
          round={false}
          type={type}
          width={width}
          {...props}
        >
          {label}
          {!round && icon}
        </LinkButton>
      )
    }
  }

  return (
    <PrimaryButton
      {...props}
      disabled={disabled}
      round={round}
      type={type}
      icon={icon}
      width={width}
    >
      {label}
      {!round && icon}
    </PrimaryButton>
  )
}
