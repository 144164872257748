import styled from 'styled-components'
import {
  compose,
  background,
  space,
  layout,
  typography,
  flexbox,
  color
} from 'styled-system'

import { MainBannerProps } from './MainBanner.types'

export const MainBannerWrapper = styled.div<MainBannerProps>`
  ${compose(space, layout, flexbox, typography)}
`
export const MainBannerContainer = styled.div<MainBannerProps>`
  ${compose(background, space, typography, layout, flexbox, color)}
  font-family:"Poppins";
`

export const MainBannerTitle = styled.div<MainBannerProps>`
  ${compose(space, typography)}
`

export const MainBannerSubTitle = styled.div<MainBannerProps>`
  ${compose(space, typography, layout)}
`
export const MainBannerChildren = styled.div<MainBannerProps>`
  ${compose(space, flexbox, layout)}
`
