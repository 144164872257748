import React, { MutableRefObject } from 'react'

interface AuthContextInterface {
  token: MutableRefObject<string> | null
  isLoggedIn: boolean
  authError: string
  renewToken(): void
  logIn(cpf: string, password: string, captcha: string): Promise<void>
  clearError(): void
  logOut(): void
}

const AuthContext = React.createContext<AuthContextInterface>({
  token: null,
  isLoggedIn: false,
  authError: '',
  renewToken: () => {},
  logIn: async (
    cpf: string,
    password: string,
    captcha: string
  ): Promise<void> => {},
  clearError: () => {},
  logOut: () => {}
})

export default AuthContext
