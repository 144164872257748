import styled from 'styled-components'
import { color, ColorProps, compose, space, SpaceProps } from 'styled-system'

interface FooterProps extends SpaceProps, ColorProps {
  width?: string
  height?: string
  children?: any
}

export const FooterWrapper = styled.div<FooterProps>`
  width: 100%;
`
export const FooterContainer = styled.div<FooterProps>`
  ${compose(space, color)}
  width: 100%;
  position: relative;
  height: 3.75em;
  line-height: 3.75em;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
`
