import styled from 'styled-components'
import {
  compose,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  color,
  ColorProps
} from 'styled-system'

interface ComposeProps extends SpaceProps, LayoutProps, ColorProps {}

export const LogoImg = styled.img<ComposeProps>`
  ${compose(color, space, layout)}
  max-height: 80px;
`
