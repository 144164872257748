import React from 'react'
import { theme } from 'src/styles/theme'

import {
  TimelineBulletsContainer,
  TimelineBulletsItem
} from './Timeline.styles'
import { TimelineProps } from './Timeline.types'

export const TimelineBullets: React.FC<TimelineProps> = ({ items, step }) => {
  return (
    <TimelineBulletsContainer>
      {items?.map((_, index) => (
        <TimelineBulletsItem
          step={Number(step)}
          mt={3}
          mx={1}
          key={index}
          bg={step === index ? theme.colors.cdb.secondary : theme.colors.white}
        />
      ))}
    </TimelineBulletsContainer>
  )
}
