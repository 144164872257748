const theme = {
  breakpoints: ['32rem', '40rem', '52rem', '64rem', '80rem'],
  space: [0, 4, 8, 12, 16, 20, 32, 56, 64, 128, 256, 512],
  fontSizes: [12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72],
  colors: {
    background: '#F0F2F8',
    primary: '#2984C3',
    primary_text: '#fff',
    text: 'black',
    secondary: 'transparent',

    green: '#8FC043',
    orange: '#F9AF42',
    red: '#EC6360',

    white: '#FFFFFF',
    white80: '#E5E5E5',
    white70: '#E6E6E6',
    white50: '#BFBFBF',

    gray: '#AFAFAF',
    romanSilver: '#828894',
    black: '#000000',

    alliar: {
      primary: '#9E4191',
      secondary: '#263576',

      lightPurple: '#B472AC'
    },

    cdb: {
      primary: '#3484c3',
      secondary: '#0C274D',

      lightBlue: '#39CAFC'
    }
  },
  logo: {
    cdb: {
      white: 'logo_cdb_white.svg',
      colorful: 'logo_cdb.svg'
    }
  }
}

// @ts-ignore
theme.breakpoints.xs = theme.breakpoints[0]
// @ts-ignore
theme.breakpoints.sm = theme.breakpoints[1]
// @ts-ignore
theme.breakpoints.md = theme.breakpoints[2]
// @ts-ignore
theme.breakpoints.lg = theme.breakpoints[3]
// @ts-ignore
theme.breakpoints.xl = theme.breakpoints[4]

export { theme }
