/* eslint-disable quotes */
import { CenterWrapper, Container } from './WebCheckin.styles'
import BannerImg from '../../assets/images/bg_webchekin.png'

import { NavBar } from 'src/components/NavBar'
import { Logo } from 'src/components/Logo'
import { Button } from 'src/components/Button'
import { MainBanner } from 'src/components/MainBanner'
import { Feature } from 'src/components/Feature'
import { Faq } from 'src/components/Faq'
import { Timeline } from 'src/components/Timeline'
import Footer from 'src/components/Footer'

import { FeatureTips, TimelineItems } from 'src/mocks'
import { WebCheckinTitle } from 'src/styles/common/typography'
import { OutlinedButton } from 'src/components/Button/OutlinedButton'
import React from 'react'
import { FaqItems } from 'src/mocks/Faq.mock'

import { useLocation } from 'react-router-dom'

import TagManager from 'react-gtm-module'

export const WebCheckin: React.FC = () => {
  const search = useLocation().search
  const domain = new URLSearchParams(search).get('domain')

  const isGTMEnabled =
    domain === 'cdb' || domain === 'umdi' || domain === 'goldimagem'
  if (isGTMEnabled) {
    const tagManagerArgs = {
      gtmId: 'GTM-WJW7FP6'
    }
    TagManager.initialize(tagManagerArgs)
  }

  function scrollToTarget(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault()

    document
      .getElementById('fazer-seu-webcheckin')
      ?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <Container>
      <NavBar id="NavBar">
        <Logo height={{ _: '2.889rem', xs: 'auto' }} domain={domain || 'cdb'} />
      </NavBar>

      <MainBanner
        id="MainBanner"
        title="Web Check-In"
        subtitle="Um jeito fácil de economizar algo muito valioso, o seu tempo."
        color="white"
        backgroundImage={'url(' + BannerImg + ')'}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <OutlinedButton
          onClick={scrollToTarget}
          mx={{ _: 4, xs: 2 }}
          mt="1.111rem"
          mobLarge
          dark
        >
          Conhecer Web Check-In
        </OutlinedButton>
      </MainBanner>

      <Feature items={FeatureTips} />

      <WebCheckinTitle
        id="fazer-seu-webcheckin"
        mt={{ _: '3.5rem', xs: '7.5rem' }}
        mb={{ _: '2rem', xs: '3.125rem' }}
        mx="auto"
        fontSize={{ _: '1.375rem', xs: '2rem' }}
        color="#2984C3"
        px={{ _: '1.875rem', xs: '2.5rem' }}
      >
        Veja como fazer seu Web Check-In
      </WebCheckinTitle>

      <Timeline items={TimelineItems} />

      <CenterWrapper display="block" textAlign="center">
        <Button
          toUrl={`https://www.cdb.com.br/agendamento-online/${domain}#/user/login?redirect=meus-agendamentos`}
          width="calc(100% - 2.222rem)"
          maxWidth="300px"
          mx="1.111rem"
          mt={7}
          mobLarge
        >
          Fazer Web Check-In
        </Button>
      </CenterWrapper>

      <WebCheckinTitle
        mt={{ _: '3.5rem', xs: '7.5rem' }}
        mb={{ _: '2rem', xs: '3.125rem' }}
        mx="auto"
        fontSize={{ _: '1.375rem', xs: '2rem' }}
        color="#2984C3"
        px={{ _: '1.875rem', xs: '2.5rem' }}
      >
        Descubra mais sobre o Web Check-In
      </WebCheckinTitle>

      <Faq items={FaqItems} />

      <CenterWrapper display="block" textAlign="center">
        <Button
          toUrl={`https://www.cdb.com.br/agendamento-online/${domain}#/user/login?redirect=meus-agendamentos`}
          width="calc(100% - 2.222rem)"
          maxWidth="300px"
          mx="1.111rem"
          mb={{ _: '3.5rem', xs: '7.5rem' }}
          mt={{ _: '1.5rem', xs: '1.25rem' }}
          mobLarge
        >
          Fazer Web Check-In
        </Button>
      </CenterWrapper>

      <Footer
        id="footer"
        mensage={`${new Date().getFullYear()} ALLIANÇA. TODOS OS DIREITOS RESERVADOS`}
      />
    </Container>
  )
}
