export const colors = {
  common: {
    green: '#8FC043',
    orange: '#F9AF42',
    red: '#EC6360',

    white: '#FFFFFF',
    white80: '#E5E5E5',
    white50: '#BFBFBF',

    grey: '#AFAFAF',
    romanSilver: '#828894',
    black: '#000000'
  },

  alliar: {
    primary: '#9E4191',
    secondary: '#263576',

    lightPurple: '#B472AC',
    backgroundWhite: '#F0F2F8'
  },

  cdb: {
    primary: '#2984C3',
    secondary: '#0C274D',

    lightBlue: '#39CAFC'
  }
}
