import styled, { css } from 'styled-components'
import {
  compose,
  border,
  color,
  layout,
  space,
  typography
} from 'styled-system'

export const ButtonWrapper = styled.button<any>`
  ${compose(layout, space, typography, color, border)}
  font-family:"Poppins";
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;

  ${props =>
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        cursor: pointer;
        justify-content: space-between;
        opacity: 0.7;
      }
    `}
`
