import { colors } from 'src/constants/color'

import styled from 'styled-components'
import { color, compose, layout, position, space } from 'styled-system'

import { IconProps } from '../../types/Icon.types'

export const IconWrapper = styled.div<IconProps>`
  ${compose(space, layout, color, position)}
  display: flex;
  width: ${props => (props.width ? props.width : '1.25em')};
  height: 1.25em;
  top: 1.4em;
  z-index: 10;
  color: ${props => (props.color ? props.color : colors.common.grey)};

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;

  ${props =>
    props.rotate
      ? '-moz-transform: rotate(' +
        props.rotate +
        'deg);' +
        '-webkit-transform: rotate(' +
        props.rotate +
        'deg);' +
        '-o-transform: rotate(' +
        props.rotate +
        'deg);' +
        '-ms-transform: rotate(' +
        props.rotate +
        'deg);' +
        'transform: rotate(' +
        props.rotate +
        'deg);'
      : null}
`
