import { useContext, useEffect } from 'react'
import AuthContext from '../../store/Auth/auth-context'
import { Button } from '../../stories/Button/Button'
import useBffClient from '../../hooks/use-bff-client'

const Home = () => {
  const authCtx = useContext(AuthContext)
  const { httpPost } = useBffClient()

  const logoutHandler = () => {
    authCtx.logOut()
  }

  useEffect(() => {
    const success = (data: any) => {
      console.log(data)
    }

    const error = (error: any) => {
      console.log(error)
    }

    const requestUserData = async () => {
      const body = {
        nome: 'string',
        cpf: 'string',
        email: 'string',
        dataNascimento: 'string',
        genero: 0,
        peso: 0,
        altura: 0
      }
      await httpPost('/schedule/auth', body, {}, success, error)
    }

    requestUserData()
  }, [])

  return (
    <>
      <h1>Home</h1>
      <h2>
        Você está logado. <Button onClick={logoutHandler} label="Sair" />
      </h2>

      <h3>User Token: {authCtx.token?.current}</h3>
    </>
  )
}

export default Home
