import { useCallback } from 'react'
import axios from 'axios'

const useHttp = () => {
  const sendRequest = useCallback(
    async (requestConfig, applyData, handleError) => {
      try {
        const response = await axios(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : 'GET',
          headers: requestConfig.headers ? requestConfig.headers : {},
          data: requestConfig.body ? JSON.stringify(requestConfig.body) : null
        })

        const data = response.data
        applyData(data)
      } catch (error: any) {
        handleError(error)
      }
    },
    []
  )

  return {
    sendRequest
  }
}

export default useHttp
