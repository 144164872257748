import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/theme'
import GlobalStyle from './styles/global'

import RouterManager from './pages/routerManager'
import AuthProvider from './store/Auth/AuthProvider'

const getUrl = () => {
  const URL = window.location.hostname
  return URL
}

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <GlobalStyle />
        <RouterManager />
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
