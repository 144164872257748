import { theme } from 'src/styles/theme'
import { ButtonProps } from './Button.types'
import { ButtonWrapper } from './ButtonWrapper'

export const Button = (props: ButtonProps) => {
  const { dark, children, ...rest } = props
  const { colors } = theme

  return (
    <ButtonWrapper
      {...rest}
      color={!dark ? colors.white : colors.cdb.secondary}
      borderColor={dark ? colors.white : colors.cdb.secondary}
      backgroundColor={dark ? colors.white : colors.cdb.secondary}
      borderWidth={0}
    >
      {children}
    </ButtonWrapper>
  )
}
